<template>
  <div class="wrapper load">
    <transition name="fade">
      <div v-if="!getMapReadyStatus && !getAuthChecked" class="spinner">
        <div class="loader">
          <svg class="car" width="102" height="40" xmlns="http://www.w3.org/2000/svg">
            <g transform="translate(2 1)" stroke="#002742" fill="none" fill-rule="evenodd" stroke-linecap="round"
              stroke-linejoin="round">
              <path class="car__body"
                d="M47.293 2.375C52.927.792 54.017.805 54.017.805c2.613-.445 6.838-.337 9.42.237l8.381 1.863c2.59.576 6.164 2.606 7.98 4.531l6.348 6.732 6.245 1.877c3.098.508 5.609 3.431 5.609 6.507v4.206c0 .29-2.536 4.189-5.687 4.189H36.808c-2.655 0-4.34-2.1-3.688-4.67 0 0 3.71-19.944 14.173-23.902zM36.5 15.5h54.01"
                stroke-width="3" />
              <ellipse class="car__wheel--left" stroke-width="3.2" fill="#FFF" cx="83.493" cy="30.25" rx="6.922"
                ry="6.808" />
              <ellipse class="car__wheel--right" stroke-width="3.2" fill="#FFF" cx="46.511" cy="30.25" rx="6.922"
                ry="6.808" />
              <path class="car__line car__line--top" d="M22.5 16.5H2.475" stroke-width="3" />
              <path class="car__line car__line--middle" d="M20.5 23.5H.4755" stroke-width="3" />
              <path class="car__line car__line--bottom" d="M25.5 9.5h-19" stroke-width="3" />
            </g>
          </svg>
        </div>
      </div>
    </transition>
    <transition name="slide-right">
      <div v-touch:swipe.left="closeMobileMenu" v-if="getMobileMenuStatus" class="mobile_menu">
        <div class="menu_logo">
          <img src="images/logo_new.png" alt="">
        </div>
        <div class="menu_links">
          <div class="link" @click="setListPage">{{ $t('stations') }}</div>
          <div class="link" @click="setFavoritesPage">{{ $t('switch_table.favorite_stations') }}</div>
          <!-- <div class="link" @click="setRfidPage">RFID</div> -->
          <div class="link" @click="setProfilePage">{{ $t('message.top_menu_my_profile') }}</div>
          <div class="link" @click="setHistoryPage">{{ $t("message.top_menu_history") }}</div>
          <div class="link" @click="setBalancePage">{{ $t("payments") }}</div>
        </div>
        <div v-if="getAuthStatus" class="user_data">
          <div class="user_data_phone" v-if="this.$store.getters.getAccountInfo.Phone != ''">
            <svg id="Layer_1" enable-background="new 0 0 512.021 512.021" viewBox="0 0 512.021 512.021"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="m367.988 512.021c-16.528 0-32.916-2.922-48.941-8.744-70.598-25.646-136.128-67.416-189.508-120.795s-95.15-118.91-120.795-189.508c-8.241-22.688-10.673-46.108-7.226-69.612 3.229-22.016 11.757-43.389 24.663-61.809 12.963-18.501 30.245-33.889 49.977-44.5 21.042-11.315 44.009-17.053 68.265-17.053 7.544 0 14.064 5.271 15.645 12.647l25.114 117.199c1.137 5.307-.494 10.829-4.331 14.667l-42.913 42.912c40.482 80.486 106.17 146.174 186.656 186.656l42.912-42.913c3.837-3.837 9.36-5.466 14.667-4.331l117.199 25.114c7.377 1.581 12.647 8.101 12.647 15.645 0 24.256-5.738 47.224-17.054 68.266-10.611 19.732-25.999 37.014-44.5 49.977-18.419 12.906-39.792 21.434-61.809 24.663-6.899 1.013-13.797 1.518-20.668 1.519zm-236.349-479.321c-31.995 3.532-60.393 20.302-79.251 47.217-21.206 30.265-26.151 67.49-13.567 102.132 49.304 135.726 155.425 241.847 291.151 291.151 34.641 12.584 71.867 7.64 102.132-13.567 26.915-18.858 43.685-47.256 47.217-79.251l-95.341-20.43-44.816 44.816c-4.769 4.769-12.015 6.036-18.117 3.168-95.19-44.72-172.242-121.772-216.962-216.962-2.867-6.103-1.601-13.349 3.168-18.117l44.816-44.816z" />
              <path
                d="m496.02 272c-8.836 0-16-7.164-16-16 0-123.514-100.486-224-224-224-8.836 0-16-7.164-16-16s7.164-16 16-16c68.381 0 132.668 26.628 181.02 74.98s74.98 112.639 74.98 181.02c0 8.836-7.163 16-16 16z" />
              <path
                d="m432.02 272c-8.836 0-16-7.164-16-16 0-88.224-71.776-160-160-160-8.836 0-16-7.164-16-16s7.164-16 16-16c105.869 0 192 86.131 192 192 0 8.836-7.163 16-16 16z" />
              <path
                d="m368.02 272c-8.836 0-16-7.164-16-16 0-52.935-43.065-96-96-96-8.836 0-16-7.164-16-16s7.164-16 16-16c70.58 0 128 57.42 128 128 0 8.836-7.163 16-16 16z" />
            </svg>
            <span>+{{ this.$store.getters.getAccountInfo.Phone }}</span>
          </div>
          <div class="user_data_email" v-if="this.$store.getters.getAccountInfo.Email != ''">
            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512 512"
              style="enable-background:new 0 0 512 512;" xml:space="preserve">
              <path d="M467,61H45C20.218,61,0,81.196,0,106v300c0,24.72,20.128,45,45,45h422c24.72,0,45-20.128,45-45V106
                    C512,81.28,491.872,61,467,61z M460.786,91L256.954,294.833L51.359,91H460.786z M30,399.788V112.069l144.479,143.24L30,399.788z
                    M51.213,421l144.57-144.57l50.657,50.222c5.864,5.814,15.327,5.795,21.167-0.046L317,277.213L460.787,421H51.213z M482,399.787
                    L338.213,256L482,112.212V399.787z" />
            </svg>
            <span>{{ this.$store.getters.getAccountInfo.Email }}</span>
          </div>
        </div>
        <div class="menu_contacts">
          <div style="display: none" class="flot_mobile_btn" @click="open_in_new_tab()">
            <img src="/images/flot_svg.svg" alt="" />
          </div>
          <div class="logout_block">
            <button class="logout_btn" @click="confirmLogout = true" v-if="!confirmLogout">
              {{ $t('my_profile_page.logout_btn') }}
            </button>
            <div v-else class="confirm">
              <span>{{ $t('my_profile_page.logout_popup_title') }}</span>
              <button class="yep" @click="LogoutUser">{{ $t('my_profile_page.logout_popup_yes_btn') }}</button>
              <button class="nope" @click="confirmLogout = false">{{
                $t('my_profile_page.logout_popup_no_btn')
              }}
              </button>
            </div>
          </div>
          <a href="tel:+359877179947">
            <svg id="Layer_1" enable-background="new 0 0 512.021 512.021" viewBox="0 0 512.021 512.021"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="m367.988 512.021c-16.528 0-32.916-2.922-48.941-8.744-70.598-25.646-136.128-67.416-189.508-120.795s-95.15-118.91-120.795-189.508c-8.241-22.688-10.673-46.108-7.226-69.612 3.229-22.016 11.757-43.389 24.663-61.809 12.963-18.501 30.245-33.889 49.977-44.5 21.042-11.315 44.009-17.053 68.265-17.053 7.544 0 14.064 5.271 15.645 12.647l25.114 117.199c1.137 5.307-.494 10.829-4.331 14.667l-42.913 42.912c40.482 80.486 106.17 146.174 186.656 186.656l42.912-42.913c3.837-3.837 9.36-5.466 14.667-4.331l117.199 25.114c7.377 1.581 12.647 8.101 12.647 15.645 0 24.256-5.738 47.224-17.054 68.266-10.611 19.732-25.999 37.014-44.5 49.977-18.419 12.906-39.792 21.434-61.809 24.663-6.899 1.013-13.797 1.518-20.668 1.519zm-236.349-479.321c-31.995 3.532-60.393 20.302-79.251 47.217-21.206 30.265-26.151 67.49-13.567 102.132 49.304 135.726 155.425 241.847 291.151 291.151 34.641 12.584 71.867 7.64 102.132-13.567 26.915-18.858 43.685-47.256 47.217-79.251l-95.341-20.43-44.816 44.816c-4.769 4.769-12.015 6.036-18.117 3.168-95.19-44.72-172.242-121.772-216.962-216.962-2.867-6.103-1.601-13.349 3.168-18.117l44.816-44.816z" />
              <path
                d="m496.02 272c-8.836 0-16-7.164-16-16 0-123.514-100.486-224-224-224-8.836 0-16-7.164-16-16s7.164-16 16-16c68.381 0 132.668 26.628 181.02 74.98s74.98 112.639 74.98 181.02c0 8.836-7.163 16-16 16z" />
              <path
                d="m432.02 272c-8.836 0-16-7.164-16-16 0-88.224-71.776-160-160-160-8.836 0-16-7.164-16-16s7.164-16 16-16c105.869 0 192 86.131 192 192 0 8.836-7.163 16-16 16z" />
              <path
                d="m368.02 272c-8.836 0-16-7.164-16-16 0-52.935-43.065-96-96-96-8.836 0-16-7.164-16-16s7.164-16 16-16c70.58 0 128 57.42 128 128 0 8.836-7.163 16-16 16z" />
            </svg>
            <span>+359877179947</span></a>
          <a href="mailto:bg@voltspot.net">
            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512 512"
              style="enable-background:new 0 0 512 512;" xml:space="preserve">
              <path d="M467,61H45C20.218,61,0,81.196,0,106v300c0,24.72,20.128,45,45,45h422c24.72,0,45-20.128,45-45V106
                  C512,81.28,491.872,61,467,61z M460.786,91L256.954,294.833L51.359,91H460.786z M30,399.788V112.069l144.479,143.24L30,399.788z
                   M51.213,421l144.57-144.57l50.657,50.222c5.864,5.814,15.327,5.795,21.167-0.046L317,277.213L460.787,421H51.213z M482,399.787
                  L338.213,256L482,112.212V399.787z" />
            </svg>
            <span>bg@voltspot.net</span>
          </a>
        </div>
      </div>
    </transition>
    <div class="wrapper_inner">
      <div v-if="getAuthStatus && getMainPage && getIsMobile" v-touch:swipe.right="openMobileMenu"
        :class="{ hidden: getMobileMenuStatus }" class="slide_open_mobile_menu">
        <svg v-touch:swipe.right="openMobileMenu" :class="{ hidden: getMobileMenuStatus }" @click="openMobileMenu"
          id="Capa_1" viewBox="0 0 515.556 515.556" xmlns="http://www.w3.org/2000/svg">
          <path fill="#62A321"
            d="m260.671 257.778-257.778 257.778v-128.886l128.889-128.892-128.897-128.886.008-128.892z" />
        </svg>
      </div>
      <transition name="slide-left">
        <div v-touch:swipe.left="closeMobileMenu" @click="closeMobileMenu" v-if="getMobileMenuStatus"
          class="close_menu">
          <svg v-touch:swipe.left="closeMobileMenu" @click="closeMobileMenu" id="Capa_1" viewBox="0 0 515.556 515.556"
            xmlns="http://www.w3.org/2000/svg">
            <path fill="#62A321"
              d="m260.671 257.778-257.778 257.778v-128.886l128.889-128.892-128.897-128.886.008-128.892z" />
          </svg>
        </div>
      </transition>
      <TopMenu />
      <MapPage />
      <ProfilePage v-if="getProfilePage" />
      <RfidPage v-if="getRfidPage" />
      <HistoryPage v-if="getHistoryPage" />
      <BalancePage v-if="getBalancePage" />
      <AuthModal v-if="this.$store.state.AuthModal === true" />
    </div>
    <notifications position="top right" />
    <!-- <div v-if="msg" class="msg">
      <div class="msg-inner">
        <span class="msg1">Maintenance work on the voltspot.</span>
        <hr>
        <span class="msg2">
          Start: <v>2024-08-14 07:00 UTC+0</v> <br> End: <v>2024-08-14 07:30 UTC+0</v>
        </span>
        <span @click="msg = false" class="msg-close">&#10005;</span>
      </div>
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MapPage from './components/MapPage.vue'
import TopMenu from "./components/TopMenu";
import AuthModal from "./components/AuthModal";
import ProfilePage from "./components/ProfilePage";
import HistoryPage from "./components/HistoryPage";
import BalancePage from "./components/BalancePage";
import RfidPage from "./components/RfidPage";

export default {
  name: 'App',
  components: {
    BalancePage,
    HistoryPage,
    RfidPage,
    ProfilePage,
    AuthModal,
    TopMenu,
    MapPage
  },
  data() {
    return {
      msg: true,
      confirmLogout: false,
    }
  },
  methods: {
    open_in_new_tab() {
      if (this.$store.getters.getIsProd === true) {
        window.location.href = 'https://my.voltflot.net/?sid=' + localStorage.getItem('AuthToken') + '&lang=' + localStorage.getItem('SessionLang');
      } else {
        window.location.href = 'https://my2.voltflot.net/?sid=' + localStorage.getItem('AuthToken') + '&lang=' + localStorage.getItem('SessionLang');
      }
    },
    // Установить страницу "Карта"
    setMainPage() {
      this.$store.commit('setMainPage')
    },
    // Открыть мобильное меню
    openMobileMenu() {
      this.setMainPage()
      this.$store.commit('setMobileMenuStatus', true)
      let browser = window.navigator.userAgent.substr(-40,)
      let firefox = browser.indexOf('Firefox')
      let mi = browser.indexOf('MiuiBrowser')
      if (firefox >= 0 || mi >= 0) {
        setTimeout(() => document.querySelector('.mobile_menu').style.background = 'rgba(255, 255, 255, 0.95)', 10)
      }
    },
    // Закрыть мобильное меню
    closeMobileMenu() {
      this.confirmLogout = false
      this.$store.commit('setMobileMenuStatus', false)
    },
    setRfidPage() {
      this.closeMobileMenu()
      this.$store.commit('setRfidPage')
    },
    // Установить страницу "Список станций"
    setListPage() {
      this.closeMobileMenu()
      this.$store.commit('setListPage')
    },
    // Установить страницу "Список избранных станций"
    setFavoritesPage() {
      this.closeMobileMenu()
      this.$store.commit('setFavoritesPage')
    },
    // Установить страницу "Мой профиль"
    setProfilePage() {
      this.closeMobileMenu()
      this.$store.commit('setProfilePage')
    },
    setHistoryPage() {
      this.closeMobileMenu()
      this.$store.commit('setHistoryPage')
    },
    setBalancePage() {
      this.closeMobileMenu()
      this.$store.commit('setBalancePage')
    },
    // Выйти из аккаунта
    LogoutUser() {
      this.closeMobileMenu()
      localStorage.removeItem('AuthToken')
      this.$store.commit('setMainPage')
      this.$store.state.AuthStatus = false
      this.$store.dispatch('gotAccountInfo')
      window.location.reload()
    }
  },
  mounted() {
    let rmLoad = function () {
      document.querySelector('.wrapper').classList.remove("load")
    }
    setTimeout(() => rmLoad(), 600)
    // Закрыть мобильное меню после загрузки страницы (на всякий случай)
    this.closeMobileMenu()
  },
  created() {
    let lastVisibilityChangeTime = Date.now();
    function handleVisibilityChange() {
      if (document.hidden) {
        lastVisibilityChangeTime = Date.now();
      } else {
        const currentTime = Date.now();
        const timeSinceLastChange = currentTime - lastVisibilityChangeTime;
        if (timeSinceLastChange > 10 * 60 * 1000) {
          location.reload();
        }
      }
    }
    document.addEventListener("visibilitychange", handleVisibilityChange);

    this.$store.dispatch('setIsProdStatus')
    this.$store.dispatch('initSocket')
    this.$store.dispatch('gotStations')
    this.$store.dispatch('gotGeoPosition')
    this.$store.dispatch('realtimeUserPosition')
    // Определение языка браузера юзера
    this.$store.dispatch('setSessionLang')
    if (localStorage.SessionLang != null)
      this.$i18n.locale = localStorage.SessionLang
    // Проверка авторизации юзера
    if (localStorage.getItem('AuthToken')) {
      this.$store.dispatch('gotAccountInfo')
    } else {
      this.$store.state.AuthStatus = false
    }
    // Пересчет высоты экрана после ресайза в VH
    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
    window.addEventListener('resize', () => {
      let vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    })
    // Определение устройства юзера
    if (window.matchMedia("(max-width: 991px)").matches) {
      this.$store.state.isMobile = true
    }
  },
  computed: {
    ...mapGetters([
      'getProfilePage',
      'getHistoryPage',
      'getRfidPage',
      'getAccountInfo',
      'getBalancePage',
      'getMobileMenuStatus',
      'getAuthStatus',
      'getMainPage',
      'getIsMobile',
      'getMapReadyStatus',
      'getAuthChecked',
    ]),
  }
}
</script>

<style lang="scss" scoped>
.msg {
  position: absolute;
  top: calc(50% - 150px);
  right: calc(50% - 200px);
  z-index: 999999;

  hr {
    margin: 20px 0 30px;
    width: 100%;
    border: 1px solid #00000033;
  }

  .msg1 {
    color: red;
    font-size: 16px;
    font-weight: bolder;
    text-transform: uppercase;
  }

  .msg2 {
    color: red;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;

    v {
      text-decoration: underline;
    }
  }

  .msg-inner {
    width: 400px;
    height: 200px;
    position: relative;
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(5px);
    box-shadow: 0 3px 10px 0 rgba(32, 33, 36, 0.6);
    padding: 30px 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .msg-close {
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 20px;
  }
}

.user_data {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 5px;
  margin-top: 20px;

  svg {
    display: none;
    // width: 20px;
    // margin-right: 10px;

    path {
      fill: gray;
    }
  }

  .user_data_phone {
    color: gray;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 1rem;
  }

  .user_data_email {
    color: gray;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 1rem;
  }
}

.spinner {
  position: absolute;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: 9999;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  justify-content: center;

  .loader {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .car {
    &__body {
      animation: shake 0.2s ease-in-out infinite alternate;
    }

    &__line {
      transform-origin: center right;
      stroke-dasharray: 22;
      animation: line 0.8s ease-in-out infinite;
      animation-fill-mode: both;

      &--top {
        animation-delay: 0s;
      }

      &--middle {
        animation-delay: 0.2s;
      }

      &--bottom {
        animation-delay: 0.4s;
      }
    }
  }

  @keyframes shake {
    0% {
      transform: translateY(-1%);
    }

    100% {
      transform: translateY(3%);
    }
  }

  @keyframes line {
    0% {
      stroke-dashoffset: 22;
    }

    25% {
      stroke-dashoffset: 22;
    }

    50% {
      stroke-dashoffset: 0;
    }

    51% {
      stroke-dashoffset: 0;
    }

    80% {
      stroke-dashoffset: -22;
    }

    100% {
      stroke-dashoffset: -22;
    }
  }

}

.vue-notification {
  // styling
  margin: 0 5px 5px;
  padding: 10px;
  font-size: 12px;
  color: #ffffff;

  // default (blue)
  background: #44A4FC;
  border-left: 5px solid #187FE7;

  // types (green, amber, red)
  &.success {
    background: #68CD86;
    border-left-color: #42A85F;
  }

  &.warn {
    background: #ffb648;
    border-left-color: #f48a06;
  }

  &.error {
    background: #E54D42;
    border-left-color: #B82E24;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.slide-left-leave-active {
  transition: .2s;
}

.slide-left-enter-active {
  transition: .6s ease;
}

.slide-left-enter {
  transform: translate(100%, 0);
}

.slide-left-leave-to {
  transform: translate(100%, 0);
}

.slide-right-leave-active,
.slide-right-enter-active {
  transition: .2s;
}

.slide-right-enter {
  transform: translate(-100%, 0);
}

.slide-right-leave-to {
  transform: translate(-100%, 0);
}

.wrapper {
  max-height: 100vh;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
  position: relative;
  transition: opacity 0.6s ease;
  opacity: 1;

  &.load {
    transition: opacity 0.6s ease;
    opacity: 0;
  }

  .mobile_menu {
    height: 100%;
    top: 0;
    left: 0;
    width: 80vw;
    position: absolute;
    display: flex;
    flex-direction: column;
    z-index: 99999;
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(3px);
    color: #000;
    box-shadow: 0 3px 10px 0 rgba(32, 33, 36, 0.6);

    .menu_logo {
      display: flex;
      height: 20%;
      align-items: center;
      justify-content: center;
      padding: 0 20px;

      img {
        max-width: 70%;
      }
    }

    .menu_links {
      height: 50%;
      padding: 0;
      display: flex;
      justify-content: center;
      flex-direction: column;
      box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.3);

      .link {
        height: 100%;
        text-align: center;
        font-size: 1.5rem;
        font-weight: 600;
        display: flex;
        color: #62A321;
        justify-content: center;
        align-items: center;
        background: rgba(255, 255, 255, 0.4);
        border-top: 1px solid #C6C6C6;

        &:first-child {
          border-top: none;
        }
      }
    }

    .menu_contacts {
      // height: 30%;
      display: flex;
      flex-direction: column;
      padding: 20px;
      justify-content: flex-end;

      .logout_block {
        display: flex;
        width: 100%;
        margin: 0 0 20px 0;

        button.logout_btn {
          border: none;
          display: flex;
          width: 100%;
          justify-content: center;
          align-items: center;
          box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.3);
          height: 40px;
          font-weight: 500;
          background: rgba(255, 255, 255, 0.5);
          border-radius: 5px;
          font-size: 1.4rem;
          color: red;
        }

        .confirm {
          display: flex;
          justify-content: space-between;
          width: 100%;
          position: relative;

          span {
            position: absolute;
            line-height: 1;
            top: -1.8rem;
            left: 0;
            width: 100%;
            height: 1.4rem;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1.2rem;
          }

          button {
            width: 48%;
            border: none;
            display: flex;
            outline: none;
            justify-content: center;
            align-items: center;
            box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.3);
            height: 40px;
            font-weight: 400;
            background: rgba(255, 255, 255, 0.5);
            border-radius: 5px;
            font-size: 1.4rem;
            color: gray;
          }
        }
      }

      .flot_mobile_btn {
        display: flex;
        width: 100%;
        margin: 0 0 20px 0;
        justify-content: center;
        align-items: center;
        box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.3);
        height: 60px;
        background: rgba(255, 255, 255, 0.5);
        border-radius: 5px;
        padding: 5px 0;

        img {
          width: 100px;
        }
      }

      a {
        text-decoration: none;
        color: gray;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        font-size: 1rem;
        margin-bottom: 5px;

        &:last-child {
          margin-bottom: 0;
        }

        svg {
          width: 20px;
          margin-right: 10px;

          path {
            fill: gray
          }
        }
      }
    }
  }

  .wrapper_inner {
    position: relative;
    transition: all .4s;

    .slide_open_mobile_menu {
      position: fixed;
      left: 0;
      top: 0;
      height: 100%;
      width: 1px;
      background: #62A321;
      z-index: 401;
      transition: all .6s linear;

      &.hidden {
        transform: translateX(-100%);
      }

      svg {
        position: fixed;
        left: 0;
        top: calc(50% - 20px);
        z-index: 99999;
        height: 40px;
        transition: all .6s linear;

        &.hidden {
          transform: translateX(-100%);
        }
      }
    }

    .close_menu {
      position: fixed;
      top: 0;
      right: 0;
      width: 20vw;
      height: 100%;
      z-index: 99999;
      display: flex;
      backdrop-filter: blur(1px);
      justify-content: center;
      align-items: center;
      background: rgba(255, 255, 255, 0.4);

      svg {
        width: 100%;
        transform: rotate(180deg);
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .msg {
    right: 10%;
    left: 10%;

    .msg1 {
      font-size: 14px;
      text-align: center;
    }

    .msg-inner {
      width: 100%;
    }
  }
}
</style>
